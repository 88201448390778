const messageElClass = "password-equality";
const inequalityMessage = "Your passwords do not match";

function setupEvents() {
  document.querySelectorAll('input[type="password"]').forEach(function(el) {
    const id = el.id;
    const confEl = document.querySelector(`#${id}_confirmation`);

    if (!confEl) return;

    function compareValues(e) {
      if (confEl.value.trim().length === 0 || el.value === confEl.value) {
        equal(confEl);
      } else {
        if (e.type === 'blur') {
          notEqual(confEl);
        }
      }
    }

    el.addEventListener("input", compareValues);
    confEl.addEventListener("blur", compareValues);
    confEl.addEventListener("input", compareValues);
  });
}

function notEqual(el) {
  const nextSibling = el.nextSibling;
  if (
    nextSibling &&
    nextSibling.classList &&
    nextSibling.classList.contains(messageElClass)
  )
    return;

  const messageEl = document.createElement("p");
  messageEl.classList.add(messageElClass);
  messageEl.innerText = inequalityMessage;
  insertAfter(messageEl, el);
}

function equal(el) {
  const nextSibling = el.nextSibling;
  if (
    nextSibling &&
    nextSibling.classList &&
    nextSibling.classList.contains(messageElClass)
  )
    nextSibling.parentNode.removeChild(nextSibling);
}

document.addEventListener("DOMContentLoaded", function() {
  setupEvents();
});

// create function, it expects 2 values.
function insertAfter(newElement, targetElement) {
  // target is what you want it to go after. Look for this elements parent.
  var parent = targetElement.parentNode;

  // if the parents lastchild is the targetElement...
  if (parent.lastChild == targetElement) {
    // add the newElement after the target element.
    parent.appendChild(newElement);
  } else {
    // else the target has siblings, insert the new element between the target and it's next sibling.
    parent.insertBefore(newElement, targetElement.nextSibling);
  }
}
