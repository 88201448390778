import zxcvbn from "zxcvbn";

window.zxcvbn = zxcvbn;

window.StrongPasswordField = (function() {
  var StrongPasswordField = function() {
    this.min_length = 6;
    this.strongPasswordFields = [];
    this.passwordMetaData = {
      score: 0,
      text: "",
      strengthLevels: 5
    };
    this.strengthMeterSelector = ".password-strength-meter";
    this.minimumStrength = 2;
    this.inputIsBlank = true;
  };

  StrongPasswordField.prototype.validate = function() {
    this.strongPasswordFields = this.getStrongPasswordFields();
    for (var i = 0; i < this.strongPasswordFields.length; i++) {
      this.strongPasswordFields[i].addEventListener(
        "input",
        this.onValidate.bind(this)
      );
    }
  };

  StrongPasswordField.prototype.onValidate = function(e) {
    var password = e.target.value;
    if (window.zxcvbn === undefined) {
      this.basicAdapter(password);
    } else {
      this.inputIsBlank = password.trim() === "";

      this.zxcvbnAdapter(password);
      this.loadTheme();
    }
  };

  StrongPasswordField.prototype.zxcvbnAdapter = function(password) {
    var adapter = new ZxcvbnAdapter();
    this.passwordMetaData = Utils.mergeObjects(
      this.passwordMetaData,
      adapter.evaluatePassword(password)
    );
  };

  StrongPasswordField.prototype.basicAdapter = function(password) {
    if (password.length < this.min_length) {
      alert("password length must be at least" + this.min_length);
    }
  };

  StrongPasswordField.prototype.getStrongPasswordFields = function() {
    return document.querySelectorAll(
      'input[type="password"][data-strong-password=true]'
    );
  };

  StrongPasswordField.prototype.loadTheme = function() {
    // use default theme, will be configurable in the future
    var theme = new DefaultTheme(this);
    theme.attachTheme();
  };

  StrongPasswordField.prototype.getStrengthMeterElement = function() {
    return document.querySelector(this.strengthMeterSelector);
  };

  var DefaultTheme = (function() {
    var DefaultTheme = function(spf) {
      this.spf = spf;
      this.passwordMetaData = spf.passwordMetaData;
      this.minimumStrength = spf.minimumStrength;
      this.inputIsBlank = spf.inputIsBlank;
      this.colors = {
        0: "red",
        1: "red",
        2: "orange",
        3: "blue",
        4: "green"
      };
    };

    DefaultTheme.prototype.meter = function() {
      if (this.inputIsBlank) return 0;

      return (
        (this.passwordMetaData.score / this.passwordMetaData.strengthLevels) *
          100 +
        20
      );
    };

    DefaultTheme.prototype.color = function() {
      return this.colors[this.passwordMetaData.score];
    };

    DefaultTheme.prototype.attachTheme = function() {
      var strengthMeterElement = this.spf.getStrengthMeterElement();
      if (strengthMeterElement.innerHTML.trim() === "") {
        this.initialLoad(strengthMeterElement);
      } else {
        this.update(strengthMeterElement);
      }
    };

    DefaultTheme.prototype.update = function(elem) {
      var meterElem = this.getMeterElement(elem);

      meterElem.style.backgroundColor = this.color();
      meterElem.style.width = this.meter().toString() + "%";
      meterElem.style.height = "5px";

      this.setUserGuidelinesText(elem);
    };

    DefaultTheme.prototype.initialLoad = function(elem) {
      elem.innerHTML = '<div class="spf-meter"></div><p class="spf-text"></p>';
      var meterElem = this.getMeterElement(elem);

      meterElem.style.backgroundColor = this.color();
      meterElem.style.width = this.meter().toString() + "%";
      meterElem.style.height = "5px";

      this.setUserGuidelinesText(elem);
    };

    DefaultTheme.prototype.setUserGuidelinesText = function(elem) {
      var textElem = this.getTextElement(elem);

      if (
        !this.inputIsBlank &&
        this.passwordMetaData.score < this.minimumStrength
      ) {
        textElem.innerText =
          "Your password is not strong enough. Try adding a combination of numbers, letters (lowercase and capital), and special characters like $ or &. You may also try adding words to improve the strength.";
      } else {
        textElem.innerText = "";
      }
    };

    DefaultTheme.prototype.getMeterElement = function(elem) {
      return elem.querySelector(".spf-meter");
    };

    DefaultTheme.prototype.getTextElement = function(elem) {
      return elem.querySelector(".spf-text");
    };

    return DefaultTheme;
  })();

  var Utils = function() {};
  Utils.mergeObjects = function(target, obj) {
    for (var keyInObj in obj) {
      if (!obj.hasOwnProperty(keyInObj) || !target.hasOwnProperty(keyInObj))
        continue;
      target[keyInObj] = obj[keyInObj];
    }
    return target;
  };

  var ZxcvbnAdapter = (function() {
    var ZxcvbnAdapter = function() {
      this.strengthLevels = 5;
      this.strength = {
        0: "worst",
        1: "bad",
        2: "weak",
        3: "good",
        4: "strong"
      };
      this.score = 0;
      this.strengthText = "";
    };

    ZxcvbnAdapter.prototype.evaluatePassword = function(password) {
      var result = zxcvbn(password);
      this.score = result.score;
      this.strengthText = this.strength[result.score];
      return {
        score: this.score,
        text: this.strengthText,
        strengthLevels: this.strengthLevels
      };
    };

    return ZxcvbnAdapter;
  })();

  return StrongPasswordField;
})();

document.addEventListener("DOMContentLoaded", function() {
  var strongPasswordField = new StrongPasswordField({
    strengthMeterSelector: ".password-strength-meter"
  });
  strongPasswordField.validate();
});
